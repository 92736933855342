<template>
  <div class="d-flex flex-column max-height-100">
    <div class="d-flex flex-grow-1 fill-height" :style="{ height: `calc(100vh - ${headerHeight}px)` }">
    <iframe
      v-if="nodeRedUrl"
      ref="nodeRedIframe"
      :src="nodeRedUrl"
      width="100%"
      height="100%"
      style="border: none;"
      @load="sendMessageToIframe"
    ></iframe>
    <p v-else>{{ $t('automationTool.loading') }}</p>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import constants from '@/utils/constants';
// Llevarlo a una clase que cuelgue de la carpeta common
import axios from 'axios';

export default {
  name: "NodeRedEmbed",
  data() {
    return {
      nodeRedUrl: null
    };
  },
  beforeCreate() {
    // Log en beforeCreate
    console.log("beforeCreate: No hay acceso a 'data'");
  },
  created() {
    // Log en created
    console.log("created: Ahora sí hay acceso a 'data'");
  },
  computed: {
    headerHeight() {
      return this.$store.state.global.appHeaderMapHeightInPx;
    }
  },
  async mounted() {
    try {
      console.log('info: mounted dashboardpanel');
      var userProperties = this.$store.getters.getUserProperties;
      console.log('userProperties', userProperties);

      const token = userProperties.organizationtoken; // Obtén el token del store
      console.log('token', token);

      var appProperties = this.$store.getters.getApplication;
      console.log('App properties:', appProperties);
      const baseUrl = appProperties.urlbase;

      const proxyUrl = '/api'; // URL del proxy definido en vue.config.js
      const config = {
        url: proxyUrl, // Apunto al proxy
        headers: { 
          Authorization: `Bearer ${token}`, // Agrego el token en el header
          'baseUrl': baseUrl
        }
      }

      const response = await axios.get(`${config.url}`, { headers: config.headers });

      /*const response = await axios.get("/api/get-node-red-url", {
        headers: { Authorization: `Bearer ${token}` }
      });*/
      console.log('Respuesta proxy dinamico')
      console.log('response', response);
      this.nodeRedUrl = response.data.url; // Configura la URL del iframe

    } catch (error) {
      console.error('Error al cargar la URL de Node-RED', error);
    }
		const puiContainerHeader = document.getElementsByClassName('pui-containerHeader')[0];
		puiContainerHeader.style.display = 'none';
	},
	beforeDestroy() {
		console.log('info: beforedestroy dashboardpanel');

		if (document.getElementsByClassName('pui-containerHeader')[0]) {
			document.getElementsByClassName('pui-containerHeader')[0].style.display = '';
		}
	},
  methods: {
    sendMessageToIframe() {
      const iframe = this.$refs.nodeRedIframe;
      const iframeWindow = iframe.contentWindow;

      // Enviar mensaje al iframe para cambiar el texto del header
      setTimeout(() => {
        iframeWindow.postMessage(
          {
            type: 'changeHeaderText',
            text: this.$t('automationTool.title')
          },
          //`${constants.baseURL_AUTOMATION_TOOL}:1895`
          this.nodeRedUrl
        );
        //
        
      }, 3000); // Esperar 3 segundos
    } //end sendMessageToIframe
  }
};
</script>

<style scoped>
div {
  height: 100vh;
}
</style>